import { LogResponse } from 'app/models';
import moment from 'moment';

const LogListColumns = () => [
  {
    text: 'Time',
    dataField: 'time',
    canSort: false,
    formatter: (log: LogResponse) => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          {moment(log.createdAt).format('YYYY-MM-DD HH:mm')}
        </h5>
      </>
    ),
  },
  {
    text: 'User',
    dataField: 'user',
    canSort: false,
    formatter: (log: LogResponse) => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          {log?.User?.userName || '-'}
        </h5>
      </>
    ),
  },
  {
    text: 'Action',
    dataField: 'action',
    canSort: false,
    formatter: (log: LogResponse) => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          {log.type === 'SignIn' ? 'Logged in' : log.type}
        </h5>
      </>
    ),
  },
  {
    text: 'IP Address',
    dataField: 'IPaddress',
    canSort: false,
    formatter: (log: LogResponse) => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          {log.ip || '-'}
        </h5>
      </>
    ),
  },
];

export default LogListColumns;
