import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

interface Props {
  children: React.ReactChild;
}

function Layout({ children }: Props) {
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById('preloader')!.style.display = 'block';
      document.getElementById('status')!.style.display = 'block';

      setTimeout(function () {
        document.getElementById('preloader')!.style.display = 'none';
        document.getElementById('status')!.style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader')!.style.display = 'none';
      document.getElementById('status')!.style.display = 'none';
    }
  }, [isPreloader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">{children}</div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default Layout;
