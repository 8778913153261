import { SurveyFormData, Survey } from 'app/models';
import { v4 as uuidv4 } from 'uuid';

export const formatSurveyFormData = (survey: Survey): SurveyFormData => {
  const {
    chineseTitle,
    englishTitle,
    englishDescription,
    chineseDescription,
    deliveryDate,
    region,
    make,
    SurveyTemplateQuestions,
    vehicleBodyType,
    type,
  } = survey;
  const data: SurveyFormData = {
    chineseTitle,
    englishTitle,
    englishDescription,
    chineseDescription,
    deliveryDate,
    region,
    make,
    vehicleBodyType,
    type,
    SurveyTemplateQuestions: SurveyTemplateQuestions.map(question => ({
      ...question,
      id: uuidv4(),
      gradingUpper: question.gradingUpper.toString(),
      gradingLower: question.gradingLower.toString(),
    })),
  };

  return data;
};
