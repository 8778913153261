import { TimeStamp } from './Common';
import { User } from './User';

export interface CallCentreRecord extends TimeStamp {
  callCentreRecordId: number;
  type: CallCentreRecordType;
  filledSurveyDate: '2024-05-03';
  regName: 'Mackenzie54';
  regNo: 'YY1234';
  regDate: '2024-04-30';
  chassisNo: 'ewTUv3VqzDHayNabNq';
  make: 'MTB';
  deliveryDate: '2024-04-28';
  invoiceDate: '2024-05-06';
  appointmentDate: '2024-05-07';
  isCallAllowed: null;
  isSMSAllowed: null;
  isEmailAllowed: null;
  isWhatsAppAllowed: null;
  lastCallStatus: null;
  lastCallDate: null;
  lastCallByUserId: null;
  region: 'Hong Kong';
  createdAt: '2024-05-03T10:20:49.000Z';
  updatedAt: '2024-05-03T10:20:49.000Z';
}

export enum CallCentreRecordTypeOptions {
  FirstTimeFreeCheck = 'First Time Free Check',
  AppointmentReminder = 'Appointment Reminder',
  AfterService = 'After Service',
  Special = 'Special',
}

export type CallCentreRecordType = keyof typeof CallCentreRecordTypeOptions;

export interface CreateContactHistoryParams {
  type: ContactHistoryType;
  orderNumber: number;
  isReachable: boolean;
  confirmStatus: ConfirmStatusType | null;
  confirmedDetail: ConfirmDetailType | NotConfrimedType | null;
  serviceCentre: string | null;
  bookingDate: string | null;
  remarks: string | null;
  unreachableReason: string | null;
  complaint?: ExtraComment | null;
  compliment?: ExtraComment | null;
  feedback?: ExtraComment | null;
  generalEnquiry?: ExtraComment | null;
  templateLanguage: string | null;
}

export type ContactHistoryType = 'Call' | 'SMS' | 'WhatsApp';

export enum ConfirmStatusOptions {
  Confirm = 'Confirm',
  'Not Confirm' = 'Not Confirm',
}

export type ConfirmStatusType = keyof typeof ConfirmStatusOptions;

export enum ConfirmDetailOptions {
  'Confirm Booking' = 'Confirm Booking',
  'Done Already' = 'Done Already',
  'Driven In' = 'Driven In',
  'Rebook' = 'Rebook',
}

export type ConfirmDetailType = keyof typeof ConfirmDetailOptions;

export enum NotConfirmedOptions {
  Busy = 'Busy',
  'Call Again Later' = 'Call Again Later',
  'Cancel Booking' = 'Cancel Booking',
  'Car Sold' = 'Car Sold',
  'Customer Will Book Later' = 'Customer Will Book Later',
  'Customer Will Call Back' = 'Customer Will Call Back',
  'No Need Call' = 'No Need Call',
  'Do Not Call Again' = 'Do Not Call Again',
  'Other (Specify in Remark)' = 'Other (Specify in Remark)',
}

export type NotConfrimedType = keyof typeof NotConfirmedOptions;

export interface ContactHistory extends TimeStamp, CreateContactHistoryParams {
  User: User;
  contactHistoryId: number;
  callCentreRecordId: number;
  userId: number;
}

export enum UnreachableReasonOptions {
  'No Answer' = 'No Answer',
  'Wrong Number' = 'Wrong Number',
  'Other (Specify in Remark)' = 'Other (Specify in Remark)',
}

export interface ExtraComment {
  department: string | null;
  afterSalesBranch: string | null;
  caseReason: string | null;
  description: string | null;
}
