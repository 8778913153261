import { IMAGE_URL } from 'app/config';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const toPercentage = (value: number, numberOfDecimal?: number) => {
  if (numberOfDecimal === 0) {
    return Math.round(value * 100) + '%';
  } else {
    return (value * 100).toFixed(numberOfDecimal || 2) + '%';
  }
};

export const checkIfNumberOnly = (value: string) => {
  const regex = /^[0-9]*$/g;
  return regex.test(value);
};
