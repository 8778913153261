export const ROUTES = {
  // home
  HOME: '/',

  // admin
  ADMIN: '/admins',
  ADMIN_NEW: '/admins/new',
  ADMIN_PASSWORD: '/admins/password',

  // user
  USER: '/users',

  // call center
  CALL_CENTER_SEARCH: '/call-center/search-all',
  CALL_CENTER_FREE_CHECK: '/call-center/free-check',
  CALL_CENTER: '/call-center',
  CALL_CENTER_DETAIL: '/call-center/:type/:id',
  CALL_CENTER_APPOINTMENT_REMINDER: '/call-center/appointment-reminder',
  CALL_CENTER_AFTER_SERVICE: '/call-center/after-service',
  CALL_CENTER_SPECIAL: '/call-center/special',
  CALL_CENTER_EXPORT: '/call-center/export',
  CALL_CENTER_STATISTIC: '/call-center/statistic',

  // survey
  SURVEY_SEARCH: '/survey/search-all',
  SURVEY_MANAGE: '/survey/manage',
  SURVEY_E_SURVEY: '/survey/e-survey',
  SURVEY_PC_HK: '/survey/pc-survey-hk',
  SURVEY_CV_HK: '/survey/cv-survey-hk',
  SURVEY_PC_MACAU: '/survey/pc-survey-macau',
  SURVEY_CV_MACAU: '/survey/cv-survey-macau',
  SURVEY_CUSTOMER_DETAIL: '/survey/detail/:surveyType/:invoiceNo',
  SURVEY_CUSTOMER_DETAIL_WITHOUT_TYPE_AND_INVOICENUM: '/survey/detail',
  SURVEY_TEMPLATE: '/survey',
  SURVEY_NEW: '/survey/:type',
  SURVEY_EDIT: '/survey/:type/:id',
  SURVEY_COPY: '/survey/:type/:id',

  // report
  REPORT_MFG: '/report/mfg-report',
  REPORT_E_REPORT: '/report/e-report',
  REPORT_PC_HK: '/report/pc-report-hk',
  REPORT_CV_HK: '/report/cv-report-hk',
  REPORT_PC_MACAU: '/report/pc-report-macau',
  REPORT_CV_MACAU: '/report/cv-report-macau',

  // brand
  BRAND_E_BRAND: '/brand/e-brand',
  BRAND_PC_HK: '/brand/pc-brand-hk',
  BRAND_CV_HK: '/brand/cv-brand-hk',
  BRAND_PC_MACAU: '/brand/pc-brand-macau',
  BRAND_CV_MACAU: '/brand/cv-brand-macau',

  // option
  OPTIONS: '/options/e-center-options',

  // log
  LOG_LIST: '/log',

  // upload
  UPLOAD: '/upload',

  // config
  CONFIG: '/config',

  // auth
  LOGIN: '/login',
  LOGOUT: '/logout',

  // user survey
  USER_SURVEY: '/user-survey/:uid',
  USER_SURVEY_WITHOUT_ID: '/user-survey',

  // not found
  NOT_FOUND: '/notFound',
};
