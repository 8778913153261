import { TOAST_TIME } from 'app/config';
import { AxiosInterceptor } from 'app/services';
import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from 'styles/global-styles';
import 'app/assets/scss/theme.scss';
import 'app/assets/scss/custom/custom.scss';

import { Routes } from './route/Routes';

export function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>Sime Darby E Survey Web</title>
          <meta name="description" content="Admin Panel" />
        </Helmet>
        <AxiosInterceptor>
          <Routes />
        </AxiosInterceptor>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />
      </BrowserRouter>
    </HelmetProvider>
  );
}
