import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'app/models';
import { logout } from 'app/services/UserService';
import { toast } from 'react-toastify';
import { AppThunk } from 'store';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  profile: User | null;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
  profile: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    setProfile(state, action: PayloadAction<User | null>) {
      state.profile = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { loginStart, loginSuccess, loginFail, setProfile } =
  authSlice.actions;
export default authSlice.reducer;

// dispatch actions

export const logoutAdmin =
  (navigate): AppThunk =>
  async dispatch => {
    try {
      await logout();
      dispatch(setProfile(null));
      navigate('/login');
    } catch (err) {
      toast.warning('Please check your network');
    }
  };
