import { UploadFileForm } from 'app/components/Form/UploadFileForm';
import { APP_NAME } from 'app/config';
import { importSaleData } from 'app/services/SaleService';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

export const UploadPage = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (
    values: { file: File },
    helper: FormikHelpers<{ file: File }>,
  ) => {
    if (values.file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', values.file);
        await importSaleData(formData);
        toast.success('File uploaded successfully.');
        helper.resetForm();
      } catch (err) {
        //console.log(err);
        toast.error('Failed to upload file.');
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn('Please select a file.');
    }
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>Upload | {APP_NAME}</title>
        </Helmet>
        <Container fluid>
          <h5 className="mb-4">UPLOAD</h5>
          <UploadFileForm loading={loading} onSubmit={onSubmit} />
        </Container>
      </div>
    </>
  );
};
