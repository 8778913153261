import { Form, Formik, FormikHelpers } from 'formik';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { BlueButton } from '../Button/BlueButton';
import { UploadFileField } from '.';

interface UploadFormItem {
  file: any;
}

const UploadInitForm: UploadFormItem = {
  file: null,
};

interface Props {
  loading: boolean;
  onSubmit: (
    values: UploadFormItem,
    helper: FormikHelpers<UploadFormItem>,
  ) => void;
}

export const UploadFileForm = ({ loading, onSubmit }: Props) => {
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={UploadInitForm}>
        {({ setFieldValue, values, errors, touched, resetForm }) => (
          <Form>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <>
                      <Row>
                        <Col lg={6}>
                          <UploadFileField name="file" />
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-4">
                        <div>
                          *Large files take time to process, please be patience.
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <BlueButton
                            text="Upload"
                            padding={[9, 67, 9, 67]}
                            type="submit"
                            loading={loading}
                          ></BlueButton>
                        </Col>
                      </Row>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
