import { Filter, ListResult, LogResponse } from 'app/models';
import Axios from './';

/**
* * List User Sign In Log , Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#get-/v1/users/log)
+*/

export interface GetUserSignInLogListParams extends Filter<LogResponse> {
  userId?: string;
}

export const getUserSignInLogList = async (
  params: GetUserSignInLogListParams,
) => {
  const { data } = await Axios.get<ListResult<LogResponse>>('/users/log', {
    params,
  });
  return data;
};
