import { User } from './User';
import { Brand } from './Brand';
import { TimeStamp } from './Common';
import { Region, SurveySubmission, SurveyTemplate } from './Survey';

export enum VehicleBodyType {
  PC = 'PC',
  CV = 'CV',
}

export type SurveyStatus = 'new' | 'sent' | 'sentProblem' | 'finish';

export type SMSorWhatsAppSentStatus = 'Sent' | 'NoSent' | 'Ignore';

export interface Sale extends TimeStamp {
  invoiceNo: string;
  uid: string;
  surveyTemplateId: number;
  regNo: string;
  regDate: string;
  type: string;
  make: string;
  chassisNo: string;
  modelRange: string;
  modelCode: string;
  modelDescription?: string | null;
  deliveryDate: string;
  customerNo: string;
  customerName: string;
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  address4?: string | null;
  mobileNo: string;
  homeTelNo?: string | null;
  officeTelNo?: string | null;
  salesManNo: string;
  salesManName: string;
  salesManOutNo?: string | null;
  salesManOutName?: string | null;
  invoiceDate: string;
  isUsed: boolean;
  salesAreaCode: string;
  salesAreaName?: string | null;
  vehicleSize?: string | null;
  transmissionType?: string | null;
  email: string;
  vehicleBodyType?: VehicleBodyType | null;
  region: Region;
  surveyStatus: SurveyStatus;
  isReachableLastCall?: boolean | null;
  isWhatsAppSent?: boolean | null;
  whatsAppSentDateTime?: string | null;
  isSMSSent?: boolean | null;
  SMSSentDateTime?: string | null;
  isSentEmail?: boolean | null;
  errorMessage?: string | null;
  remarks?: string | null;
  SurveyTemplate?: SurveyTemplate;
  Brand?: Brand;
  SurveySubmission?: SurveySubmission;
}

export enum CallStatusReachable {
  'Reachable' = 'Reachable',
  'Not reachable' = 'Not reachable',
}
export interface CallLog extends TimeStamp {
  saleCallLogId?: number;
  invoiceNo: string;
  isReachable: boolean;
  status: string;
  rating?: number | null;
  remarks?: string | null;
  calledByUserId: number;
  User?: User;
}

export interface CreateCallLogParams {
  isReachable: boolean;
  status: string;
  rating?: number;
  remarks?: string;
}

export interface CallLogForm extends Omit<CreateCallLogParams, 'isReachable'> {
  isReachable?: boolean;
}
