export const WhiteButton = (props: {
  type?: 'reset' | 'button' | 'submit';
  onClick?: () => void;
  text: string;
  className?: string;
  padding?: [number, number, number, number];
  disabled?: boolean;
}) => {
  const { onClick, text, className, type, padding, disabled } = props;
  return (
    <button
      type={type || 'reset'}
      className={
        `btn btn-default white-btn d-flex align-items-center justify-content-center text-nowrap ` +
        className
      }
      style={
        padding && padding.length >= 4
          ? {
              padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
            }
          : undefined
      }
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled}
    >
      {` ${text}`}
    </button>
  );
};
