import { AddNewCenterFormSchema } from 'app/constants/AddNewCenterFormConstant';
import { Center } from 'app/models/Center';
import { Form, Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { BlueButton } from '../Button/BlueButton';
import { WhiteButton } from '../Button/WhiteButton';
import InputField from './InputField';

export const AddNewCenterForm = () => {
  const onSubmit = async () => {};

  const AddNewCenterInitForm: Center = {
    numberCode: '',
    centerCode: '',
    centerName: '',
    region: '',
  };

  return (
    <>
      <Formik
        initialValues={AddNewCenterInitForm}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={AddNewCenterFormSchema}
      >
        {({ setFieldValue, values, errors, touched, resetForm }) => (
          <Form>
            <Row className="mb-3">
              <Col>
                <InputField
                  label="Number Code"
                  value={''}
                  name="numberCode"
                  type="text"
                />
              </Col>
              <Col>
                <InputField
                  label="Center Code"
                  value={''}
                  initialValue=""
                  name="centerCode"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <InputField
                label="Region"
                value={''}
                initialValue=""
                name="region"
                type="text"
              />
            </Row>
            <Row className="mb-3">
              <InputField
                label="Center Name"
                value={''}
                initialValue=""
                name="centerName"
                type="text"
              />
            </Row>
            <Row>
              <Col className="d-flex align-items-center">
                <BlueButton
                  text="Confirm"
                  padding={[9, 64, 9, 64]}
                  className={'me-2'}
                />
                <WhiteButton
                  text="Reset"
                  type="reset"
                  padding={[9, 50, 9, 50]}
                  onClick={() => resetForm()}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
