import { ProtectedRoute } from 'app/components/Route';
import { ROUTES } from 'app/constants/Routes';
import { useAppSelector } from 'app/hooks/store';
import { LoginPage, LogoutPage } from 'app/pages/Authentication';
import {
  BrandCVHKPage,
  BrandCVMacauPage,
  BrandPCHKPage,
  BrandPCMacauPage,
  EBrandPage,
} from 'app/pages/BrandPage';
import {
  CallCenterAfterServicePage,
  CallCenterAppointmentReminderPage,
  CallCenterExportPage,
  CallCenterFreeCheckDetailPage,
  CallCenterFreeCheckPage,
  CallCenterSearchPage,
  CallCenterSpecialPage,
  CallCenterStatisticPage,
} from 'app/pages/CallCenterPage';
import { HomePage } from 'app/pages/HomePage';
import { LogListPage } from 'app/pages/LogPage/LogListPage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { OptionsPage } from 'app/pages/OptionsPage';
import { MFGReportPage, SurveyReportPage } from 'app/pages/ReportPage';
import {
  ESurveyPage,
  SurveyCVHKPage,
  SurveyCVMacauPage,
  SurveyFormPage,
  SurveyManagePage,
  SurveyPCHKPage,
  SurveyPCMacauPage,
  UserSurveyPage,
  SurveySearchPage,
} from 'app/pages/SurveyPage';
import { CustomerDetailPage } from 'app/pages/SurveyPage/CustomerDetailPage/Loadable';
import { SystemConfigPage } from 'app/pages/SystemConfigPage';
import { UploadPage } from 'app/pages/UploadPage';
import { UserListPage } from 'app/pages/UserPage';
import { useRoutes } from 'react-router-dom';
import { RootState } from 'store';

export const Routes = () => {
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);
  const element = useRoutes([
    {
      path: ROUTES.HOME,
      element: (
        <ProtectedRoute profile={profile}>
          <HomePage />
        </ProtectedRoute>
      ),
    },

    // call center
    {
      path: ROUTES.CALL_CENTER_SEARCH,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterSearchPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_FREE_CHECK,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterFreeCheckPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_DETAIL,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterFreeCheckDetailPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_APPOINTMENT_REMINDER,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterAppointmentReminderPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_AFTER_SERVICE,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterAfterServicePage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_SPECIAL,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterSpecialPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_EXPORT,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterExportPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.CALL_CENTER_STATISTIC,
      element: (
        <ProtectedRoute profile={profile}>
          <CallCenterStatisticPage />
        </ProtectedRoute>
      ),
    },

    // Survey
    {
      path: ROUTES.SURVEY_SEARCH,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveySearchPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_MANAGE,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyManagePage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_E_SURVEY,
      element: (
        <ProtectedRoute profile={profile}>
          <ESurveyPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_PC_HK,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyPCHKPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_CV_HK,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyCVHKPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_PC_MACAU,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyPCMacauPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_CV_MACAU,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyCVMacauPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_CUSTOMER_DETAIL,
      element: (
        <ProtectedRoute profile={profile}>
          <CustomerDetailPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_EDIT,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.SURVEY_NEW,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyFormPage />
        </ProtectedRoute>
      ),
    },

    // Report
    {
      path: ROUTES.REPORT_MFG,
      element: (
        <ProtectedRoute profile={profile}>
          <MFGReportPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.REPORT_E_REPORT,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyReportPage type="e-report" />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.REPORT_PC_HK,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyReportPage type="pc-report-hk" />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.REPORT_CV_HK,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyReportPage type="cv-report-hk" />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.REPORT_PC_MACAU,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyReportPage type="pc-report-macau" />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.REPORT_CV_MACAU,
      element: (
        <ProtectedRoute profile={profile}>
          <SurveyReportPage type="cv-report-macau" />
        </ProtectedRoute>
      ),
    },

    // Brand
    {
      path: ROUTES.BRAND_E_BRAND,
      element: (
        <ProtectedRoute profile={profile}>
          <EBrandPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.BRAND_PC_HK,
      element: (
        <ProtectedRoute profile={profile}>
          <BrandPCHKPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.BRAND_CV_HK,
      element: (
        <ProtectedRoute profile={profile}>
          <BrandCVHKPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.BRAND_PC_MACAU,
      element: (
        <ProtectedRoute profile={profile}>
          <BrandPCMacauPage />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.BRAND_CV_MACAU,
      element: (
        <ProtectedRoute profile={profile}>
          <BrandCVMacauPage />
        </ProtectedRoute>
      ),
    },

    // option
    {
      path: ROUTES.OPTIONS,
      element: (
        <ProtectedRoute profile={profile}>
          <OptionsPage />
        </ProtectedRoute>
      ),
    },

    // log
    {
      path: ROUTES.USER,
      element: (
        <ProtectedRoute profile={profile}>
          <UserListPage />
        </ProtectedRoute>
      ),
    },

    // log
    {
      path: ROUTES.LOG_LIST,
      element: (
        <ProtectedRoute profile={profile}>
          <LogListPage />
        </ProtectedRoute>
      ),
    },

    // upload
    {
      path: ROUTES.UPLOAD,
      element: (
        <ProtectedRoute profile={profile}>
          <UploadPage />
        </ProtectedRoute>
      ),
    },

    {
      path: ROUTES.CONFIG,
      element: (
        <ProtectedRoute profile={profile}>
          <SystemConfigPage />
        </ProtectedRoute>
      ),
    },

    // user survey
    { path: ROUTES.USER_SURVEY, element: <UserSurveyPage /> },

    // auth
    { path: ROUTES.LOGIN, element: <LoginPage /> },
    { path: ROUTES.LOGOUT, element: <LogoutPage /> },

    // not found
    { path: ROUTES.NOT_FOUND, element: <NotFoundPage /> },
    { path: '*', element: <NotFoundPage /> },
  ]);
  return element;
};
