import { ListColumn, SortType } from 'app/models';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';

export interface CheckableConfig {
  noWrap?: boolean;
  onCheck: (item: any) => void;
  isCheckBoxShow?: (item: any) => boolean;
  selected: Record<string | number, any>;
  checkAll: () => void;
}

interface Props<T> {
  isAllowSorting?: boolean;
  columns: ListColumn<any>[];
  data: T[];
  keyField: string;
  rowOnClick?: (item: T) => void;
  checkableConfig?: CheckableConfig;
  sortOnChange?: (sort: SortType | null) => void;
  className?: string;
}
// sort type key value pair

const CustomTable = <T,>({
  columns,
  data,
  keyField,
  rowOnClick,
  checkableConfig,
  isAllowSorting = true,
  sortOnChange,
  className,
}: Props<T>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selected, checkAll, isCheckBoxShow, onCheck, noWrap } =
    checkableConfig ?? {};
  const query = new URLSearchParams(location.search);
  const desc = query.get('desc');
  const asc = query.get('asc');
  const [sortBy, setSortBy] = useState<SortType | null>(
    desc ? { desc: desc } : asc ? { asc: asc } : null,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const columnOnClick = (field: string) => {
    if (!sortBy) {
      // first click
      setSortBy({ desc: field });
    } else if (sortBy && Object.values(sortBy)[0] !== field) {
      // change other field
      setSortBy({ desc: field });
    } else if (sortBy && Object.keys(sortBy)[0] === 'desc') {
      // same field desc to asc
      setSortBy({ asc: field });
    } else if (sortBy && Object.keys(sortBy)[0] === 'asc') {
      // same field asc to unset
      setSortBy(null);
    }
  };

  useEffect(() => {
    if (sortOnChange) {
      sortOnChange(sortBy);
    } else {
      const params = new URLSearchParams(location.search);
      params.delete('desc');
      params.delete('asc');
      if (sortBy) {
        params.set(Object.keys(sortBy)[0], Object.values(sortBy)[0]);
      }
      navigate({ search: params.toString() }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  // check all btn
  let isCurrentPageAllCheck = true;
  for (let i = 0; i < data.length; i++) {
    if (!selected?.[data[i][keyField]]) {
      isCurrentPageAllCheck = false;
      break;
    }
  }
  return (
    <>
      <Table
        className={`table align-middle table-nowrap table-hover ${className}`}
        style={{ minWidth: '1100px' }}
      >
        <thead className="table-light position-sticky top-0">
          <tr>
            {selected && checkAll && isCheckBoxShow ? (
              <th
                className="align-middle"
                onClick={() => checkAll()}
                style={{ cursor: 'pointer' }}
              >
                {isCheckBoxShow && data.filter(isCheckBoxShow).length !== 0 ? (
                  <>
                    {isCurrentPageAllCheck ? (
                      <i className="bx bx-check-square check-icon active fs-5 font-size-18 text-success" />
                    ) : (
                      <i className="bx bx-square check-icon fs-5 font-size-18" />
                    )}
                  </>
                ) : null}
              </th>
            ) : null}
            {columns.map((column, index) => {
              const canSort =
                column.canSort &&
                isAllowSorting &&
                column.text !== '' &&
                column.dataField !== '' &&
                column.dataField !== 'img' &&
                column.dataField !== 'menu';
              if (column.shouldHideColumn) {
                return null;
              }
              return (
                <th
                  key={`${column.text}_${index}`}
                  onClick={() => {
                    if (canSort) columnOnClick(column.dataField as string);
                  }}
                  role={canSort ? 'button' : ''}
                  style={{ userSelect: 'none' }}
                >
                  <div className="d-flex flex-row align-items-center justify-content-ccenter">
                    <div
                      className={`mr-1 ${
                        sortBy && Object.values(sortBy)[0] === column.dataField
                          ? 'text-primary'
                          : ''
                      }`}
                    >
                      {column.text}
                    </div>
                    {canSort ? (
                      <div className="d-flex flex-column align-items-center justify-content-center px-2">
                        <i
                          className={`bx bxs-up-arrow position-relative ${
                            sortBy &&
                            Object.values(sortBy)[0] === column.dataField &&
                            Object.keys(sortBy)[0] === 'asc'
                              ? 'text-primary'
                              : ''
                          }`}
                          style={{ fontSize: 8 }}
                        />
                        <i
                          className={`bx bxs-down-arrow position-relative ${
                            sortBy &&
                            Object.values(sortBy)[0] === column.dataField &&
                            Object.keys(sortBy)[0] === 'desc'
                              ? 'text-primary'
                              : ''
                          }`}
                          style={{ fontSize: 8 }}
                        />
                      </div>
                    ) : null}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        {data.length > 0 ? (
          <tbody>
            {data.map((item, index) => {
              return (
                <tr
                  key={`${item[keyField]}_${index}`}
                  onClick={() => {
                    if (rowOnClick) {
                      rowOnClick(item);
                    }
                  }}
                  className={`${rowOnClick ? 'cursor-pointer' : ''}`}
                  style={
                    selected?.[item[keyField]]
                      ? { backgroundColor: 'rgba(25, 135, 84, 0.2)' }
                      : {}
                  }
                >
                  {onCheck && selected ? (
                    <td
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (!isCheckBoxShow || isCheckBoxShow(item))
                          onCheck(item);
                      }}
                    >
                      {!isCheckBoxShow || isCheckBoxShow(item) ? (
                        <>
                          {selected[item[keyField]] ? (
                            <i className="bx bx-check-square check-icon active fs-5 font-size-18 text-success" />
                          ) : (
                            <i className="bx bx-square check-icon fs-5 font-size-18" />
                          )}
                        </>
                      ) : null}
                    </td>
                  ) : null}
                  {columns.map((column, subIndex) => {
                    if (column.shouldHideColumn) {
                      return null;
                    }
                    if (column.formatter) {
                      return (
                        <td
                          key={`${item[keyField]}_${index}_${subIndex}`}
                          style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                        >
                          {column.formatter(item)}
                        </td>
                      );
                    } else {
                      return (
                        <td key={`${item[keyField]}_${index}_${subIndex}`}>
                          {item[column.dataField]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                colSpan={columns.length}
                className="text-center border-0 text-size-16 fw-bold"
              >
                No Data
              </td>
            </tr>
          </tbody>
        )}
      </Table>
      {isCheckBoxShow && selected && Object.keys(selected).length ? (
        <div className="text-muted mb-3">
          {Object.keys(selected).length} Selected
        </div>
      ) : null}
    </>
  );
};

export default CustomTable;
