import { LocalFile } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { IMAGE_SIZE } from 'app/config';

import './form.scss';

const UploadField = ({ ...props }: FieldHookConfig<LocalFile | null>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);

  const onDrop = (acceptedFiles: LocalFile[]) => {
    if (acceptedFiles[0].size) {
      if (acceptedFiles[0].size > IMAGE_SIZE) {
        toast.warning('Uploaded file cannot exceed a size of 10MB.');
      } else {
        helper.setValue(acceptedFiles[0]);
      }
    } else {
      toast.warning(
        'Failed to check the size of the uploaded file, please try with a different file.',
      );
    }
  };
  return (
    <Dropzone
      onDrop={acceptedFiles => {
        onDrop(acceptedFiles);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <>
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <h4>Choose File...</h4>
            </div>
          </div>
          {field.value ? (
            <p className="my-2">file: {field.value.name}</p>
          ) : null}
        </>
      )}
    </Dropzone>
  );
};

export default UploadField;
