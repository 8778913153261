import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Switch from 'react-switch';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
    </div>
  );
};

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
}

const SwitchField = (props: OtherProps & FieldHookConfig<boolean>) => {
  const [field, meta, helpers] = useField(props);
  const { label, horizontal } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
      style={
        horizontal
          ? {
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              gap: '8px',
            }
          : {}
      }
    >
      {label ? (
        <div
          style={{
            margin: '8px 8px 8px 0px',
          }}
        >
          {label}
        </div>
      ) : null}
      {/* <div className="square-switch"> */}
      <Switch
        uncheckedIcon={<Offsymbol />}
        checkedIcon={<OnSymbol />}
        onColor="#1758B6"
        onChange={e => {
          helpers.setValue(e);
        }}
        checked={field.value || false}
      />
      {/* </div> */}
    </div>
  );
};

export default SwitchField;
