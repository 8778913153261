import { PAGE_SIZE } from 'app/config';
import useBreakpoint from 'app/hooks/breakpoints';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface Props {
  count: number;
  current: number;
  onChange?: (page: number) => void;
}

function CustomPagination({ count, current, onChange }: Props) {
  const { size, width } = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const pageOnChange = (page: number) => {
    if (onChange) {
      onChange(page);
    } else {
      const params = new URLSearchParams(location.search);
      params.set('page', page.toString());
      navigate({ search: params.toString() });
    }
  };
  const total = Math.ceil(count / PAGE_SIZE);
  let pages: number[] = [];
  for (let i = 0; i < total; i++) {
    pages.push(i + 1);
  }
  const nextDisable = current === total;
  const prevDisable = current === 1;
  let start = current - (size === 'desktop' ? 5 : width > 768 ? 4 : 2),
    end = current + (size === 'desktop' ? 5 : width > 768 ? 4 : 2);
  if (start < 0) {
    start = 0;
    end = start + (size === 'desktop' ? 10 : width > 768 ? 8 : 4);
  } else if (end > total) {
    end = total;
    start = end - (size === 'desktop' ? 10 : width > 768 ? 8 : 4);
  }

  return (
    <Pagination aria-label="Page navigation example">
      {pages.length > 0 ? (
        <>
          <PaginationItem
            disabled={prevDisable}
            onClick={() => {
              if (!prevDisable) {
                pageOnChange(1);
              }
            }}
          >
            <PaginationLink first href="#" style={{ fontSize: 20 }} />
          </PaginationItem>
          <PaginationItem
            disabled={prevDisable}
            onClick={() => {
              if (!prevDisable) {
                pageOnChange(current - 1);
              }
            }}
          >
            <PaginationLink previous href="#" style={{ fontSize: 20 }} />
          </PaginationItem>
        </>
      ) : null}
      {pages.map((page, index) => {
        if (index >= start && index < end) {
          return (
            <PaginationItem
              active={page === current}
              onClick={() => pageOnChange(page)}
              key={page}
              title={page.toString()}
            >
              <PaginationLink href="#">{page}</PaginationLink>
            </PaginationItem>
          );
        }
        return null;
      })}
      {pages.length > 0 ? (
        <>
          <PaginationItem
            disabled={nextDisable}
            onClick={() => {
              if (!nextDisable) {
                pageOnChange(current + 1);
              }
            }}
            title="next page"
          >
            <PaginationLink next href="#" style={{ fontSize: 20 }} />
          </PaginationItem>
          <PaginationItem
            disabled={nextDisable}
            onClick={() => {
              if (!nextDisable) {
                pageOnChange(total);
              }
            }}
            title="last page"
          >
            <PaginationLink last href="#" style={{ fontSize: 20 }} />
          </PaginationItem>
        </>
      ) : null}
    </Pagination>
  );
}

export default CustomPagination;
