import { FieldHookConfig, useField } from 'formik';
import { OptionItems } from 'app/models';
import './form.scss';

interface OtherProps {
  label?: string;
  disabled?: boolean;
  options: OptionItems[];
}

const ButtonGroupField = (
  props: OtherProps & FieldHookConfig<string | number>,
) => {
  const [field, meta, helper] = useField(props);
  const { label, disabled, options } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
    >
      {label ? (
        <div className="mb-2" style={{ fontWeight: '500' }}>
          {label}
        </div>
      ) : null}
      <div className="d-flex flex-row align-items-center justify-content-between w-100 button-group-container form-control p-0">
        {options.map(option => (
          <button
            type="button"
            className={`clear-btn p ${
              field.value === option.value ? 'active' : ''
            }`}
            style={{ flex: 1 }}
            key={option.value}
            onClick={() => helper.setValue(option.value)}
            disabled={disabled}
          >
            {option.label}
          </button>
        ))}
      </div>

      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default ButtonGroupField;
