import * as Yup from 'yup';

export const AddNewCenterFormSchema = Yup.object().shape({
  numberCode: Yup.string()
    .required('Required')
    .min(2, 'Minimum length is 2 characters')
    .max(50, 'Maximum length is 50 characters'),
  centerCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum length is 50 characters'),
  region: Yup.string()
    .required('Required')
    .max(50, 'Maximum length is 50 characters'),
  centerName: Yup.string()
    .required('Required')
    .max(50, 'Maximum length is 50 characters'),
});
