import { TimeStamp } from './Common';
import { DateRange } from './Search';

export interface SurveyQuestion {
  chineseTitle: string;
  englishTitle: string;
  lowestGrade: string | number;
  highestGrade: string | number;
  chineseLowestGradingLabel: string;
  chineseHighestGradingLabel: string;
  englishLowestGradingLabel: string;
  englishHighestGradingLabel: string;
}

export interface ESurveySearchFormInterface {
  keywords?: string | null;
  filter?: ESurveySearchFormFilter;
  registrationDate?: DateRange | null;
  deliveryDate?: DateRange | null;
  invoiceDate?: DateRange | null;
  submissionDate?: DateRange | null;
  displayFormat?: string | null;
}

export interface ESurveySearchFormFilter {
  carMake?: string | null;
  serviceCenter?: string | null;
  sain?: string | null;
  saout?: string | null;
  surveyStatus?: string | null;
  resultStatus?: string | null;
}

export enum Region {
  'Hong Kong' = 'Hong Kong',
  'Macau' = 'Macau',
}

export enum SalesType {
  SALES = 'SALES',
  AFTERSALES = 'AFTERSALES',
  USEDCAR = 'USEDCAR',
}
export interface SurveyData {
  englishTitle: string;
  chineseTitle: string;
  englishDescription: string;
  chineseDescription: string;
  deliveryDate: string;
  region: Region;
  make: string;
  vehicleBodyType?: string;
  SurveyTemplateQuestions: SurveyQuestionsData[];
  type?: string;
}

export interface SurveyQuestionsData {
  englishTitle: string;
  chineseTitle: string;
  gradingLower: string;
  gradingUpper: string;
  gradingLowerChinese: string;
  gradingUpperChinese: string;
  gradingLowerEnglish: string;
  gradingUpperEnglish: string;
  type: SurveyQuestionsDataOption;
}

export enum SurveyQuestionsDataOption {
  'Likert Scale' = 'likertScale',
  'Short Answer' = 'text',
}

export interface SurveyQuestions extends TimeStamp, SurveyQuestionsData {
  surveyTemplateQuestionId: number;
  surveyTemplateId: number;
}

export interface Survey
  extends TimeStamp,
    Omit<SurveyData, 'SurveyTemplateQuestions'> {
  surveyTemplateId: number;
  displaySurveyTemplateId: string;
  SurveyTemplateQuestions: SurveyQuestions[];
  vehicleBodyType?: string;
  type?: string;
}

export interface SurveyQuestionFormData extends SurveyQuestionsData {
  id: string;
  surveyTemplateId?: number;
  surveyTemplateQuestionId?: number;
}
export interface SurveyFormData
  extends Omit<SurveyData, 'SurveyTemplateQuestions'> {
  SurveyTemplateQuestions: SurveyQuestionFormData[];
}

//real interface
export interface SurveyTemplateQuestion extends TimeStamp {
  surveyTemplateQuestionId: number;
  surveyTemplateId: number;
  englishTitle: string;
  chineseTitle: string;
  gradingLower: string;
  gradingUpper: string;
  gradingLowerChinese: string;
  gradingUpperChinese: string;
  gradingLowerEnglish: string;
  gradingUpperEnglish: string;
  order?: number | null;
  type: SurveyQuestionsDataOption;
}

export interface SurveyTemplate extends TimeStamp {
  surveyTemplateId: number;
  displaySurveyTemplateId?: string | null;
  englishTitle: string;
  chineseTitle: string;
  englishDescription: string;
  chineseDescription: string;
  deliveryDate: string;
  region: string;
  make: string;
  SurveyTemplateQuestions: SurveyTemplateQuestion[];
}

export interface SurveyAnswer {
  chineseTitle: string;
  englishTitle: string;
  score: number;
  text: string;
  surveyTemplateQuestionId: number;
}

export interface SurveySubmission extends TimeStamp {
  surveySubmissionId?: number;
  surveyTemplateId: number;
  invoiceNo: string;
  Answers: SurveyAnswer[];
  submittedBy: SurveySubmittedByType;
  Feedback: SurveyFeedback | null;
}

export interface SurveyFeedback {
  surveyAdvise: SurveyAdviseType[];
  surveyComplaintDescription: string | null;
  surveyComplaintReason: SurveyAdviseReasonType[];
  surveyComplimentDescription: string | null;
  surveyComplimentReason: SurveyAdviseReasonType[];
  surveyFeedback: SurveyAdviseType[];
  surveyFeedbackDescription: string | null;
  surveyFeedbackReason: SurveyAdviseReasonType[];
}

export type SurveySubmittedByType = 'Customer' | 'Office';

export enum SurveyAdvise {
  Compliment = '讚賞',
  Feedback = '建議',
  Complaint = '投訴',
}

export type SurveyAdviseType = keyof typeof SurveyAdvise;

export enum SurveyAdviseReason {
  'Salesman Service' = '營業員服務',
  'Product Quality' = '產品質量',
  'Aftersales Service (Service)' = '售後服務(維修)',
  'Aftersales Service (Parts)' = '售後服務(零件)',
}

export type SurveyAdviseReasonType = keyof typeof SurveyAdviseReason;
