import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    /* height: 100%;
    width: 100%; */
  }

  body {
    font-family: Poppins, Helvetica, Arial, sans-serif;
  }

  #root {
    /* min-height: 100%;
    min-width: 100%; */
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .clear-btn {
    background-color: transparent;
    border: 0px;
  }
  .search-button-red-doc {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    right: -5px;
    top: -5px;
    background-color: #F46A6A;
    border: 1px solid #EFF2F7;

  }
`;

// body {
//   font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
// }
