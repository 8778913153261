import { OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
// import { Label } from 'reactstrap';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  customOnChange?: (selectedOption: SingleValue<OptionItems>) => void;
  isClearable?: boolean;
  disabled?: boolean;
  className?: string;
  customRenderValue?: (options: OptionItems[]) => OptionItems | null;
  clearWrongValue?: boolean;
  additionalOnChange?: (selectedOption: SingleValue<OptionItems>) => void;
}

const SelectField = (props: OtherProps & FieldHookConfig<string | number>) => {
  const [field, meta, helpers] = useField(props);

  const {
    label,
    placeholder,
    options,
    customOnChange,
    isClearable,
    disabled,
    className,
    customRenderValue,
    clearWrongValue = true,
    additionalOnChange,
  } = props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    }
    return null;
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0074D9' : 'white',
      color: state.isSelected ? 'white' : 'black',
      ':active': {
        backgroundColor: '#0074D9',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      zIndex: 9999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  useEffect(() => {
    if (clearWrongValue) {
      if (
        field.value &&
        options.length &&
        !options.some(option => option.value === field.value)
      ) {
        helpers.setValue('');
      }
    }
  }, [clearWrongValue, field.value, helpers, options]);

  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      } ${className ? className : ''}`}
      // style={{ height: 85 }}
    >
      {label ? (
        <h6 className="mb-2" style={{ fontWeight: '500' }}>
          {props.label}
        </h6>
      ) : null}
      <Select
        styles={customStyles}
        // value={selectedMulti}
        defaultValue={
          customRenderValue ? customRenderValue(options) : rendervalue()
        }
        isMulti={false}
        placeholder={placeholder}
        options={options}
        // formatOptionLabel={option => option.label}
        classNamePrefix="select2-selection"
        {...field}
        value={customRenderValue ? customRenderValue(options) : rendervalue()}
        onChange={selectedOption => {
          if (customOnChange) {
            customOnChange(selectedOption);
          } else {
            if (selectedOption) {
              helpers.setValue(selectedOption.value);
            } else {
              helpers.setValue('');
            }
          }
          additionalOnChange?.(selectedOption);
        }}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SelectField;
