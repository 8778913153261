import React from 'react';
import { Navigate } from 'react-router-dom';

import { User } from 'app/models';
import Layout from '../VerticalLayout';

interface Props {
  profile: User | null;
  children: React.ReactChild;
}

export const ProtectedRoute = ({ profile, children }: Props) => {
  if (!profile) {
    return <Navigate to="/login" replace />;
  }

  return <Layout>{children}</Layout>;
};
