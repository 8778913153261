import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  LayoutTypes,
  LayoutModeTypes,
  LayoutWidthTypes,
  LeftSideBarThemeTypes,
  LeftBarThemeImageTypes,
  LeftSidebarTypes,
  TopBarThemeTypes,
} from 'app/models';

interface LayoutState {
  layoutType: LayoutTypes;
  layoutModeType: LayoutModeTypes;
  layoutWidth: LayoutWidthTypes;
  leftSideBarTheme: LeftSideBarThemeTypes;
  leftSideBarThemeImage: LeftBarThemeImageTypes;
  leftSideBarType: LeftSidebarTypes;
  topbarTheme: TopBarThemeTypes;
  isPreloader: boolean;
  showRightSidebar: boolean;
  isMobile: boolean;
  showSidebar: boolean;
  IsToggleSidebarMobile: boolean;
  leftMenu: boolean;
}

const initialState = {
  layoutType: 'vertical',
  layoutModeType: 'light',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'light',
  leftSideBarThemeImage: 'none',
  leftSideBarType: 'default',
  topbarTheme: 'light',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  IsToggleSidebarMobile: false,
  leftMenu: false,
} as LayoutState;

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayout(state, action: PayloadAction<LayoutTypes>) {
      state.layoutType = action.payload;
    },
    changePreloader(state, action: PayloadAction<boolean>) {
      state.isPreloader = action.payload;
    },
    changeLayoutMode(state, action: PayloadAction<LayoutModeTypes>) {
      state.layoutModeType = action.payload;
    },
    changeLayoutWidth(state, action: PayloadAction<LayoutWidthTypes>) {
      state.layoutWidth = action.payload;
    },
    changeSidebarTheme(state, action: PayloadAction<LeftSideBarThemeTypes>) {
      state.leftSideBarTheme = action.payload;
    },
    changeSidebarThemeImage(
      state,
      action: PayloadAction<LeftBarThemeImageTypes>,
    ) {
      state.leftSideBarThemeImage = action.payload;
    },
    changeSidebarType(
      state,
      action: PayloadAction<{
        sidebarType: LeftSidebarTypes;
        isMobile: boolean;
      }>,
    ) {
      const { sidebarType, isMobile } = action.payload;
      state.leftSideBarType = sidebarType;
      state.isMobile = isMobile;
      switch (sidebarType) {
        case 'compact':
          changeBodyAttribute('data-sidebar-size', 'small');
          manageBodyClass('sidebar-enable', 'remove');
          manageBodyClass('vertical-collpsed', 'remove');
          break;
        case 'icon':
          changeBodyAttribute('data-keep-enlarged', 'true');
          manageBodyClass('vertical-collpsed', 'add');
          break;
        case 'default':
          manageBodyClass('vertical-collpsed', 'remove');
          changeBodyAttribute('data-keep-enlarged', 'false');
          break;
        default:
          changeBodyAttribute('data-sidebar-size', '');
          manageBodyClass('sidebar-enable', 'remove');
          if (!isMobile) {
            manageBodyClass('vertical-collpsed', 'remove');
          }
          break;
      }
    },
    changeTopbarTheme(state, action: PayloadAction<TopBarThemeTypes>) {
      state.topbarTheme = action.payload;
    },
    showRightSidebarAction(state, action: PayloadAction<boolean>) {
      state.showRightSidebar = action.payload;
    },
    showSidebar(state, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload;
    },
    toggleLeftMenu(state, action: PayloadAction<boolean>) {
      state.leftMenu = action.payload;
    },
    toggleSidebarMobile(state, action: PayloadAction<boolean>) {
      state.IsToggleSidebarMobile = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  changeLayout,
  changePreloader,
  changeLayoutMode,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
  showSidebar,
  toggleSidebarMobile,
  toggleLeftMenu,
  reset,
} = layoutSlice.actions;

export default layoutSlice.reducer;

const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
};

const manageBodyClass = (cssClass, action = 'toggle') => {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
};
