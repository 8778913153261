import 'flatpickr/dist/themes/material_blue.css';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { InputGroup } from 'reactstrap';
import '../../assets/scss/custom/components/DateField.scss';
import moment from 'moment';

interface OtherProps {
  label: string;
  placeholder?: string;
  minDate?: Date | Date[] | null;
  onChange?: (value) => void;
}

const DateField = (props: OtherProps & FieldHookConfig<Date | null>) => {
  const [field, meta, helpers] = useField(props);
  const { label, placeholder, minDate, onChange } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
      // style={{ height: 85 }}
    >
      {label ? (
        <h6 className="mb-2" style={{ fontWeight: '500' }}>
          {props.label}
        </h6>
      ) : null}
      <div className="date-field">
        <InputGroup>
          <Flatpickr
            className="form-control d-block bg-white rounded"
            placeholder={placeholder || '日期'}
            options={{
              altInput: true,
              altFormat: 'Y-m-d',
              dateFormat: 'Y-m-d',
              minDate: minDate ? minDate[0] : null,
            }}
            value={field.value}
            onChange={date => {
              if (onChange) {
                onChange(date);
              } else {
                if (date[0]) {
                  const formattedDate = moment(date[0]).format('YYYY-MM-DD');
                  helpers.setValue(
                    formattedDate && formattedDate !== 'Invalid Date'
                      ? formattedDate
                      : date[0],
                  );
                } else {
                  helpers.setValue(null);
                }
              }
            }}
          />
        </InputGroup>
        <i
          className="bx bx-calendar-alt date-icon"
          style={{ zIndex: '1', pointerEvents: 'none' }}
        ></i>
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default DateField;
