import Axios from './';
import { Configuration } from 'app/models/Configuration';

/**
 * * Get System Config, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#get-/v1/systemConfig/current)
 */

export const getOneConfiguration = async () => {
  const { data } = await Axios.get<Configuration>('/systemConfig/current');
  return data;
};

/**
 * * Update System Config, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#put-/v1/systemConfig/current)
 */

export const updateConfiguration = async (data: Configuration) => {
  await Axios.put('/systemConfig/current', data);
};
