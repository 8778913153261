import { APP_NAME } from 'app/config';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { Form, Formik } from 'formik';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { StringArrayField } from 'app/components/Form/StringArrayField';
import { BlueButton } from 'app/components/Button/BlueButton';
import {
  getOneConfiguration,
  updateConfiguration,
} from 'app/services/ConfigurationServices';
import { Configuration } from 'app/models/Configuration';

const FORM_ITEMS: Configuration = {
  saleCentreCodeForHKMITPC: [],
  saleCentreCodeForHKMITCV: [],
  saleCentreCodeForMacauMITPC: [],
  saleCentreCodeForMacauMITCV: [],
};

export const SystemConfigPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<Configuration>(FORM_ITEMS);

  const getSystemConfiguration = useCallback(async () => {
    try {
      const res = await getOneConfiguration();
      if (res) {
        setForm({
          saleCentreCodeForHKMITPC: res.saleCentreCodeForHKMITPC,
          saleCentreCodeForHKMITCV: res.saleCentreCodeForHKMITCV,
          saleCentreCodeForMacauMITPC: res.saleCentreCodeForMacauMITPC,
          saleCentreCodeForMacauMITCV: res.saleCentreCodeForMacauMITCV,
        });
      }
    } catch (err) {
      //console.log(err);
      toast.error('Failed to get system configuration.');
    }
  }, []);

  useEffect(() => {
    getSystemConfiguration();
  }, [getSystemConfiguration]);

  const onSubmit = async (values: Configuration) => {
    try {
      setLoading(true);
      await updateConfiguration(values);
      toast.success('System configuration saved successfully.');
    } catch (err) {
      //console.log(err);
      toast.error('Failed to save system configuration.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>System Configuration | {APP_NAME}</title>
        </Helmet>
        <Container fluid>
          <h5 className="mb-4">System Configuration</h5>
          <Formik onSubmit={onSubmit} initialValues={form} enableReinitialize>
            {() => (
              <Form>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody className="d-flex flex-column gap-4">
                        <StringArrayField
                          name="saleCentreCodeForHKMITPC"
                          label="Sale Centre Code for HK MIT PC"
                          placeholder="Enter sale centre code for HK MIT PC, then press enter."
                        />

                        <StringArrayField
                          name="saleCentreCodeForHKMITCV"
                          label="Sale Centre Code for HK MIT CV"
                          placeholder="Enter sale centre code for HK MIT CV, then press enter."
                        />

                        <StringArrayField
                          name="saleCentreCodeForMacauMITPC"
                          label="Sale Centre Code for Macau MIT PC"
                          placeholder="Enter sale centre code for Macau MIT PC, then press enter."
                        />

                        <StringArrayField
                          name="saleCentreCodeForMacauMITCV"
                          label="Sale Centre Code for Macau MIT CV"
                          placeholder="Enter sale centre code for Macau MIT CV, then press enter."
                        />
                        <Row>
                          <Col className="w-100 d-flex justify-content-end">
                            <BlueButton
                              text="Save"
                              padding={[9, 67, 9, 67]}
                              type="submit"
                              loading={loading}
                            ></BlueButton>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </>
  );
};
