import { Dispatch, SetStateAction } from 'react';
import pdfIcon from '../../assets/icons/pdf.svg';
import excelIcon from '../../assets/icons/excel.svg';
import '../../assets/scss/custom/components/BlueButton.scss';

export const BlueButton = (props: {
  type?: 'reset' | 'button' | 'submit';
  setShow?: Dispatch<SetStateAction<boolean>>;
  onClick?: () => void;
  icon?:
    | 'search'
    | 'new'
    | 'pdf'
    | 'excel'
    | 'phone'
    | 'email'
    | 'whatsapp'
    | 'survey';
  padding?: [number, number, number, number];
  text: string;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  isShowDot?: boolean;
}) => {
  const {
    type,
    setShow,
    onClick,
    icon,
    text,
    className,
    padding,
    disabled,
    loading,
    isShowDot = false,
  } = props;
  return (
    <button
      type={type || 'button'}
      className={
        `btn btn-primary d-flex align-items-center justify-content-center text-noselect text-nowrap position-relative ` +
        className
      }
      style={
        padding && padding.length >= 4
          ? {
              padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
            }
          : undefined
      }
      onClick={() => {
        if (setShow) {
          setShow(true);
        } else if (onClick) {
          onClick();
        }
      }}
      disabled={disabled}
    >
      {loading ? (
        <i
          className="bx bx-loader-circle bx-spin"
          style={{ height: '20px', display: 'flex', alignItems: 'center' }}
        ></i>
      ) : (
        <>
          {icon === 'search' ? (
            <i className="bx bx-search me-2 blue-button-icon"></i>
          ) : icon === 'new' ? (
            <i className="bx bx-plus me-2 blue-button-icon"></i>
          ) : icon === 'pdf' ? (
            <img src={pdfIcon} alt="pdf" className="me-1" />
          ) : icon === 'excel' ? (
            <img src={excelIcon} alt="excel" className="me-2" />
          ) : icon === 'phone' ? (
            <i className="bx bxs-phone me-2 blue-button-icon" />
          ) : icon === 'email' ? (
            <i className="mdi mdi-email me-2 blue-button-icon" />
          ) : icon === 'whatsapp' ? (
            <i className="bx bxl-whatsapp me-2 blue-button-icon" />
          ) : icon === 'survey' ? (
            <i className="bx bx-file me-2 blue-button-icon" />
          ) : null}
          {` ${text}`}
        </>
      )}

      {isShowDot && <div className="search-button-red-doc" />}
    </button>
  );
};
