import {
  User,
  UserPasswordFormItem,
  Filter,
  ListResult,
  UpdateUserParams,
  CreateUserParams,
} from '../models';
import Axios from '.';

/**
* * User Sign In (https://sime-darby-dev.appicidea.com/doc/v2#post-/v1/me/signIn)
+*/

export interface UserLoginInParams {
  userName: string;
  password: string;
}

export const userLogin = async (params: UserLoginInParams) => {
  const { data } = await Axios.post<string>('/me/signIn', params);
  return data;
};

/**
* * Get Current User Profile (https://sime-darby-dev.appicidea.com/doc/v2#get-/v1/me/profile)
+*/

export const getCurrentUserProfile = async () => {
  const { data } = await Axios.get<User>('/me/profile');
  return data;
};

/*
 **  Update Current User Profile (https://sime-darby-dev.appicidea.com/doc/v2#patch-/v1/me/profile)
 */

export const updateCurrentUserProfile = async (params: UpdateUserParams) => {
  const { data } = await Axios.patch<User>('/me/profile');
  return data;
};

/**
* * Logout (https://sime-darby-dev.appicidea.com/doc/v2#post-/v1/me/logout)
+*/

export const logout = async () => {
  const { data } = await Axios.post('/me/logout');
  return data;
};

/**
* * Create user, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#post-/v1/users)
+*/

export const createNewUser = async (params: CreateUserParams) => {
  const { data } = await Axios.post<User>('/users', params);
  return data;
};

/**
* * Delete One User By userId, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#delete-/v1/users/-userId-)
+*/

export const deleteOneUserById = async (userId: number) => {
  const { data } = await Axios.delete<null>(`/users/${userId}`);
  return data;
};

/**
* * List all users, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#get-/v1/users)
+*/

export interface GetUserListParams extends Filter<User> {
  search?: string;
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/users', {
    params,
  });
  return data;
};

/**
* * Change current user password (https://sime-darby-dev.appicidea.com/doc/v2#post-/v1/me/changePassword)
+*/

export const updateCurrentUserPassword = async (
  params: UserPasswordFormItem,
) => {
  const { data } = await Axios.post<any>('/me/changePassword', params);
  return data;
};

/*
 ** Get One User By userId, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#get-/v1/users/-userId-)
 */

export const getOneUserById = async (userId: string | number) => {
  const { data } = await Axios.get<User>(`/users/${userId}`);
  return data;
};

/*
 **  Update One User By userId, Admin Only (https://sime-darby-dev.appicidea.com/doc/v2#patch-/v1/users/-userId-)
 */

export const updateOneUserById = async (
  userId: string | number,
  params: UpdateUserParams,
) => {
  const { data } = await Axios.patch<User>(`/users/${userId}`, params);
  return data;
};

// export const getSingleAdmin = async (adminid: string) => {
//   const { data } = await Axios.get<Admin>(`/admin/${adminid}`);
//   return data;
// };

// export const updateAdmin = async (
//   adminid: number,
//   para: { displayName: string },
// ) => {
//   const { data } = await Axios.patch<null>(`/admin/${adminid}`, para);
//   return data;
// };

// export const resetAdminPassword = async (
//   adminid: number,
//   para: { password?: string },
// ) => {
//   const { data } = await Axios.post<string>(
//     `/admin/${adminid}/resetPassword`,
//     para,
//   );
//   return data;
// };
