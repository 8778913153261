import { OptionItems, SalesType, SurveyStatus } from 'app/models';

export const translateSurveyStatus = (status: SurveyStatus) => {
  switch (status) {
    case 'new':
      return 'New';
    case 'sent':
      return 'Sent';
    case 'sentProblem':
      return 'Send Failed';
    case 'finish':
      return 'Finish';
  }
};

export const translateSalesType = (salesType: SalesType | string) => {
  switch (salesType) {
    case SalesType.SALES:
      return 'New Sales';
    case SalesType.AFTERSALES:
      return 'After Sales';
    case SalesType.USEDCAR:
      return 'Used Car';
    default:
      return salesType;
  }
};

export const formatSaleAreaList = (
  saleAreaNameList: string[],
  make?: string | null,
): OptionItems[] => {
  if (!make) {
    return saleAreaNameList.map(saleAreaName => {
      return {
        label: saleAreaName,
        value: saleAreaName,
      };
    });
  }
  return saleAreaNameList.reduce((acc: OptionItems[], saleAreaName) => {
    if (saleAreaName && saleAreaName.includes(make)) {
      acc.push({
        label: saleAreaName,
        value: saleAreaName,
      });
    }
    return acc;
  }, []);
};

export const formatSaleAreaListByMakes = (
  saleAreaNameList: string[],
  makes?: string[] | null,
): OptionItems[] => {
  if (!makes || !makes.length) {
    return saleAreaNameList.map(saleAreaName => {
      return {
        label: saleAreaName,
        value: saleAreaName,
      };
    });
  }
  return saleAreaNameList.reduce((acc: OptionItems[], saleAreaName) => {
    if (saleAreaName) {
      const isInclude = makes.some(make => saleAreaName.includes(make));
      if (isInclude) {
        acc.push({
          label: saleAreaName,
          value: saleAreaName,
        });
      }
    }
    return acc;
  }, []);
};
