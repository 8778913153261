import { FileResponse } from 'app/models';
import Axios from './index';

/**
* * Upload and resize image file (https://app-dev.appicidea.com/doc/#/File/post_v1_file_image)
* @parama file string ($binary)
+*/

export const uploadImage = async (fd: any, config?: any) => {
  const { data } = await Axios.post<FileResponse>('/files/image', fd, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // 15s for image
    timeout: 15000,
  });
  return data;
};

export const uploadFile = async fd => {
  const { data } = await Axios.post<FileResponse>('/files/file', fd);
  return data;
};
