import { Table } from 'app/components/Table';
import { APP_NAME } from 'app/config';

// import { useTableData } from 'app/hooks';
import { Helmet } from 'react-helmet-async';
import LogListColumns from './LogListColumns';
import { useTableData } from 'app/hooks';
import {
  GetUserSignInLogListParams,
  getUserSignInLogList,
} from 'app/services/LogServices';
import { LogResponse } from 'app/models';
import { PageContainer, TableContainer } from 'app/components/layout';

export const LogListPage = () => {
  const {
    dataList: logList,
    count,
    page,
  } = useTableData<LogResponse, GetUserSignInLogListParams>({
    call: getUserSignInLogList,
    filterVariableArray: ['asc', 'desc'],
  });
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>Log | {APP_NAME}</title>
        </Helmet>

        <PageContainer>
          <h5 className="mb-4">LOG</h5>
          <TableContainer count={count} page={page}>
            <Table
              columns={LogListColumns()}
              keyField="userLogId"
              data={logList}
            />
          </TableContainer>
        </PageContainer>
      </div>
    </>
  );
};
