import { TimeStamp } from './Common';
import { Region, SalesType } from './Survey';

export enum UserStatus {
  已停用 = '已停用',
  已啟用 = '已啟用',
}

export enum Accessibility {
  Call = 'Call',
  Survey = 'Survey',
}

export enum UserRole {
  admin = 'admin',
  staff = 'staff',
  warehouse = 'warehouse',
}

//for creating user
export interface UserPermissionData {
  salesType: SalesType[];
  brands: string[];
  accessibility: Accessibility[];
  cities: Region[];
}

//for getting user
export interface UserPermission {
  userPermissionId?: number;
  userId: number;
  salesType: (string | null)[];
  brands: (string | null)[];
  accessibility: (Accessibility | null)[];
  cities: (Region | null)[];
}

export interface CreateUserParams {
  email?: string;
  userName: string;
  password: string;
  role: UserRole;
  UserPermission: UserPermissionData;
}

export interface UpdateUserParams {
  userName: string;
  isReceiveNotification?: boolean;
  role: UserRole;
  UserPermission: UserPermissionData;
}

export interface CreateUserForm extends Omit<CreateUserParams, 'role'> {
  role: UserRole | null;
}

export interface UpdateUserForm extends Omit<UpdateUserParams, 'role'> {
  role: UserRole | null;
}

export interface UserLog extends TimeStamp {
  userLogId?: number;
  userId: number;
  type: string;
  ip: string;
  device?: string | null;
  location?: string | null;
  isLoginSuccess: number;
  User: User;
}

export interface User extends TimeStamp {
  userId: number;
  email: string | null;
  userName: string | null;
  notificationToken: string | null;
  isReceiveNotification: boolean;
  role: UserRole;
  status: UserStatus;
  onceId: string | null;
  uid: string | null;
  UserLogs: UserLog[];
  //rm ? when later update
  UserPermission?: UserPermission;
}

export type AdminFormItem = {
  userName: string;
  password: string;
  role: UserRole;
};

export type UserPasswordFormItem = {
  oldPassword: string;
  newPassword: string;
};
