import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'app/assets/images/logo_trans.png';
import SidebarContent from './SidebarContent';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useBreakpoint from 'app/hooks/breakpoints';

const Sidebar = () => {
  const { IsToggleSidebarMobile } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const { width } = useBreakpoint();
  return (
    <React.Fragment>
      <div
        className={`vertical-menu ${
          width <= 992 && IsToggleSidebarMobile ? 'mobileToggled' : ''
        }`}
      >
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo">
              <img
                src={logo}
                alt=""
                width={200}
                height={50}
                style={{ objectFit: 'contain' }}
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>

        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
