import { ReactNode } from 'react';
import { Region, SalesType } from './Survey';
import { VehicleBodyType } from './Sale';

export interface ListResult<T> {
  count: number;
  rows: T[];
  hasNext: boolean;
}

export interface UserResult<T> {
  count: number;
  row: T[];
}

export interface Column {
  name: string;
  field: string;
  isImage?: boolean;
  isSecureImage?: boolean;
  isAvatar?: boolean;
  dateFormat?: string;
  buildingNumber?: boolean;
}

export interface Filter<T> {
  limit: number;
  offset?: number;
  page: number;
  desc?: (keyof T | string)[];
  asc?: (keyof T | string)[];
}

export interface TimeStamp {
  createdAt: string;
  deletedAt: null | string;
  updatedAt: string;
}

export interface OptionItems {
  value: string | number;
  label: string;
}

export interface GroupedOptionItems {
  key: OptionItems;
}

export interface BreadcrumbItemProps {
  title: string;
  path: string;
}

export interface LocalFile {
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  path?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
  delete?: boolean;
  preview?: null | undefined | any | string;
  formattedSize?: string;
}

export interface FileResponse {
  fileName: string;
  url: string;
  supportSizes: string[];
}

export interface CheckBoxOption {
  value: string;
  label: string;
  check: boolean;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly OptionItems[];
}

export interface ListColumn<Type> {
  text: string;
  dataField: keyof Type | 'img' | 'menu' | '';
  canSort?: boolean;
  formatter?: (item: Type) => JSX.Element;
  shouldHideColumn?: boolean;
}

export interface SortType {
  desc?: string;
  asc?: string;
}

export interface SideBarSubRoute {
  path: string;
  name: string;
  regex: string;
}

export interface SideBarRoute {
  path: string;
  name: string;
  icon?: ReactNode;
  subRoutes?: SideBarSubRoute[];
  regex: string;
}

export interface Time {
  hour: string;
  minute: string;
  AMorPM: 'AM' | 'PM';
}

export enum FormType {
  new = 'new',
  edit = 'edit',
  copy = 'copy',
}

export interface ParamsTypeVariation {
  region?: Region | string;
  vehicleBodyType?: VehicleBodyType;
  salesType?: SalesType;
}
