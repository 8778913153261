import { createSlice } from '@reduxjs/toolkit';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store';

interface PermissionState {}

const initialState = {} as PermissionState;

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
});

export default permissionSlice.reducer;

export const useAdminPermission = () => {
  // const { auth } = useSelector((rootState: RootState) => rootState);

  // const { Auth } = auth;

  const hasPermission = (permissions: []): boolean => {
    let access = true; // default false
    return access;
  };

  return { hasPermission };
};
