export const API_URL =
  process.env.REACT_APP_API_URL || 'https://sime-darby-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = 'Sime Darby E Survey Web';
export const IMAGE_SIZE = 1024 * 10000;

// versioning
export const VERSION_NUMBER = process.env.REACT_APP_VERSION || '';
export const CURRENT_ENV = process.env.REACT_APP_ENV || 'Dev';
