import { ROUTES } from 'app/constants/Routes';
import { useAppSelector } from 'app/hooks/store';
import { Accessibility, Region, SalesType } from 'app/models';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';

export const HomePage = () => {
  const navigate = useNavigate();
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);

  const redirectRoute = React.useCallback(() => {
    if (profile?.UserPermission?.accessibility.includes(Accessibility.Call)) {
      return ROUTES.CALL_CENTER_SEARCH;
    }

    if (profile?.UserPermission?.accessibility.includes(Accessibility.Survey)) {
      if (profile.UserPermission.salesType.includes(SalesType.AFTERSALES)) {
        return ROUTES.SURVEY_E_SURVEY;
      }
      if (profile.UserPermission.salesType.includes(SalesType.SALES)) {
        if (profile.UserPermission.cities.includes(Region['Hong Kong'])) {
          return ROUTES.SURVEY_PC_HK;
        }
        if (profile.UserPermission.cities.includes(Region['Macau'])) {
          return ROUTES.SURVEY_PC_MACAU;
        }
        return ROUTES.SURVEY_PC_HK;
      }
      return ROUTES.SURVEY_E_SURVEY;
    }

    if (profile?.UserPermission?.salesType.includes(SalesType.AFTERSALES)) {
      return ROUTES.BRAND_E_BRAND;
    }
    if (profile?.UserPermission?.salesType.includes(SalesType.SALES)) {
      if (profile.UserPermission.cities.includes(Region['Hong Kong'])) {
        return ROUTES.BRAND_PC_HK;
      }
      if (profile.UserPermission.cities.includes(Region['Macau'])) {
        return ROUTES.BRAND_PC_MACAU;
      }
      return ROUTES.BRAND_PC_HK;
    }
    return ROUTES.BRAND_E_BRAND;
  }, [profile]);

  React.useEffect(() => {
    return navigate(redirectRoute());
  }, [navigate, redirectRoute]);
  return <></>;
};
