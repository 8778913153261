import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '..';

const LinkSchema = Yup.object().shape({
  link: Yup.string()
    .matches(
      /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
      'URL 格式有誤',
    )
    .required('必填項目'),
});

interface FormItem {
  link: string;
}
interface Props {
  linkOnSubmit: (value: FormItem) => void;
}

const LinkForm = ({ linkOnSubmit }: Props) => {
  const onSubmit = values => {
    linkOnSubmit(values);
  };

  return (
    <Formik
      initialValues={{ link: '' }}
      validationSchema={LinkSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className="form-horizontal">
          <div className="mb-3">
            <InputField
              name="link"
              label="Link"
              placeholder="請輸入Link"
              type="text"
            />
          </div>
          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              type="submit"
            >
              確定
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LinkForm;
