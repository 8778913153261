import { Card, CardBody } from 'reactstrap';
import CustomPagination from '../Table/CustomPagination';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  count: number;
  page: number;
  footerLeft?: React.ReactNode;
}

export const TableContainer = ({
  children,
  count,
  page,
  footerLeft,
}: Props) => {
  return (
    // TODO: extract the height calculation to a constant
    <Card>
      <CardBody style={{ height: 'calc(100vh - 94px - 24px - 37px)' }}>
        <div
          className="w-100 table-responsive"
          style={{
            height: 'calc(100vh - 94px - 24px - 37px - 40px - 50px)',
          }}
        >
          {children}
        </div>
        <div
          className={clsx(
            'pagination pagination-rounded inner-custom-pagination w-100 flex align-items-center justify-content-end',
            footerLeft ? 'justify-content-between' : 'justify-content-end',
          )}
        >
          {footerLeft ? footerLeft : null}
          <CustomPagination count={count} current={page} />
        </div>
      </CardBody>
    </Card>
  );
};
