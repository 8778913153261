import { BlueButton } from 'app/components/Button/BlueButton';
import Dialog from 'app/components/Dialog';
import { AddNewCenterForm } from 'app/components/Form/AddNewCenterForm';
import { Table } from 'app/components/Table';
import CustomPagination from 'app/components/Table/CustomPagination';
import { APP_NAME } from 'app/config';

import { useState } from 'react';
// import { useTableData } from 'app/hooks';
import { Helmet } from 'react-helmet-async';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import OptionsListColumns from './OptionsListColumns';

export const OptionsPage = () => {
  const [ShowDialog, setShowDialog] = useState<boolean>(false);
  // const {
  //   dataList: FreeCheckList,
  //   count,
  //   page,
  // } = useTableData<GetCourseListParams, CourseData>({
  //   call: getCourseList,
  //   filterVariableArray: [
  //     'asc',
  //     'desc',
  //     'isOnline',
  //     'startDate',
  //     'endDate',
  //     'courseName',
  //     'isFull',
  //     'courseStatus',
  //   ],
  // });
  return (
    <>
      <>
        <Dialog
          size="md"
          children={<AddNewCenterForm />}
          visible={ShowDialog}
          title={'Add New Center'}
          onClose={() => setShowDialog(false)}
        />
      </>
      <div className="page-content">
        <Helmet>
          <title>Center | {APP_NAME}</title>
        </Helmet>

        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5>CENTER</h5>
            <BlueButton
              setShow={setShowDialog}
              padding={[9, 23, 9, 23]}
              text="New Center"
              icon="new"
            />
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={OptionsListColumns()}
                            keyField="userId"
                            data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center">
                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                        <CustomPagination count={100} current={1} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
