import ProfileMenu from 'app/components/CommonForBoth/TopbarDropdown/ProfileMenu';
import 'app/assets/scss/Header.scss';
import useBreakpoint from 'app/hooks/breakpoints';
import { useDispatch } from 'react-redux';
import { toggleSidebarMobile } from 'store/Layout';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
//i18n
// import { useTranslation } from 'react-i18next';

interface HeaderProps {
  isFromSurvey?: boolean;
}

function Header({ isFromSurvey }: HeaderProps) {
  // const { t } = useTranslation();
  const { width } = useBreakpoint();
  const dispatch = useDispatch();
  const { IsToggleSidebarMobile } = useSelector(
    (rootState: RootState) => rootState.layout,
  );

  const toggleSidebar = () => {
    dispatch(toggleSidebarMobile(!IsToggleSidebarMobile));
  };

  return (
    <>
      <header id={`${!isFromSurvey ? 'page-topbar' : 'page-topbar-survey'}`}>
        <div className="navbar-header justify-content-between">
          <div>
            {width <= 992 && !isFromSurvey ? (
              <button
                type="button"
                onClick={() => {
                  toggleSidebar();
                }}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            ) : null}
          </div>
          <div className="d-flex">
            {/* {isFromSurvey ? null : (
              <div className="header-bell-container">
                <i className="bx bx-bell header-bell"></i>
                <span className="badge bg-primary rounded-pill header-bell-badge">
                  3
                </span>
              </div>
            )} */}

            <div className="dropdown d-none d-lg-inline-block ms-1"></div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
