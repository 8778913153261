const OptionsListColumns = () => [
  {
    text: 'Number Code',
    dataField: 'numberCode',
    canSort: false,
    formatter: () => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          01
        </h5>
      </>
    ),
  },
  {
    text: 'Center Code',
    dataField: 'centerCode',
    canSort: false,
    formatter: () => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          KC
        </h5>
      </>
    ),
  },
  {
    text: 'Center Name',
    dataField: 'centerName',
    canSort: false,
    formatter: () => (
      <>
        <h5
          className="font-size-14 mb-1"
          style={{
            maxWidth: '300px',
            whiteSpace: 'break-spaces',
          }}
        >
          SDMSV-Kwai Chung Workshop
        </h5>
      </>
    ),
  },
  {
    text: 'Edit',
    dataField: '',
    canSort: false,
    formatter: () => (
      <>
        <ul className="list-inline font-size-20 contact-links mb-0">
          <li className="list-inline-item px-2">
            <div>
              <i
                className="bx bxs-edit-alt"
                style={{ color: '#1758B6', fontSize: '18px' }}
              />
            </div>
          </li>
        </ul>
      </>
    ),
  },
];

export default OptionsListColumns;
