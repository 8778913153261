import { checkIfNumberOnly } from 'app/helpers';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import { Input } from 'reactstrap';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  initialValue?: string;
  numberOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const { label, type, placeholder, onChange, numberOnly } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
      // style={{ height: 85 }}
    >
      {label ? (
        <h6 className="mb-2" style={{ fontWeight: '500' }}>
          {props.label}
        </h6>
      ) : null}
      <Input
        style={{ height: '38px' }}
        defaultValue={props.initialValue}
        className="form-control"
        {...field}
        value={field.value || ''}
        placeholder={placeholder}
        type={type}
        autoComplete="new-password"
        disabled={props.disabled}
        onChange={e => {
          if (numberOnly) {
            if (checkIfNumberOnly(e.target.value)) {
              onChange ? onChange(e) : helpers.setValue(e.target.value);
            }
          } else {
            onChange ? onChange(e) : helpers.setValue(e.target.value);
          }
        }}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default InputField;
