import { FieldHookConfig, useField } from 'formik';
import { CSSProperties, useState } from 'react';
import { Badge, Input, Label } from 'reactstrap';

interface OtherProps {
  label: string;
  placeholder?: string;
  disabled?: boolean;
  labelStyle?: CSSProperties;
}

export const StringArrayField = (
  props: FieldHookConfig<string[]> & OtherProps,
) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [field, meta, helpers] = useField(props);
  const { label, placeholder, disabled, labelStyle } = props;

  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'text-danger' : ''
      }`}
    >
      {label ? <Label style={labelStyle}>{props.label}</Label> : null}
      <Input
        className="form-control"
        placeholder={placeholder}
        type="text"
        autoComplete="new-password"
        disabled={disabled}
        value={inputValue}
        onChange={e => {
          setInputValue(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            if (inputValue) {
              const items = inputValue.split(',');
              const newItems = items.map(item => item.trim());
              helpers.setValue([...field.value, ...newItems]);
              setInputValue('');
            }
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />

      {
        <div className="d-flex flex-wrap">
          {field.value.map((value, index) => {
            return (
              <Badge
                pill
                color="none"
                className={`position-relative badge-soft-primary ms-1 ps-3 pe-4 py-2 mt-1 font-size-12`}
                key={`string-value-${index}`}
              >
                {value}
                <button
                  type="button"
                  className="clean-button position-absolute top-50 end-0 translate-middle-y pe-1"
                  onClick={() => {
                    const newValues = field.value.filter((_, i) => i !== index);
                    helpers.setValue(newValues);
                  }}
                >
                  <i className="mdi mdi-close font-size-14 text-primary" />
                </button>
              </Badge>
            );
          })}
        </div>
      }
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};
