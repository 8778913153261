interface Props {
  children: React.ReactNode;
}

export const PageContainer = ({ children }: Props) => {
  return (
    // TODO: extract the height calculation to a constant
    <div className="w-100" style={{ height: 'calc(100vh - 94px - 24px )' }}>
      {children}
    </div>
  );
};
